import React, { useEffect, useRef, useState } from 'react'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import axios from 'axios'

import Score from '../../Layout/Score'
import { getRecommendedProducts } from '../../Utils/recommendedProducts';
import Firmness from './ResultCategories/Firmness';
import Smoothness from './ResultCategories/Smoothness';
import Hydration from './ResultCategories/Hydration';
import Dullness from './ResultCategories/Dullness';
import UnevenSkinTone from './ResultCategories/UnevenSkinTone';
import FineLines from './ResultCategories/FineLines';
import DarkSpots from './ResultCategories/DarkSpots';

import banner from '../../Assets/Result/banner/1.png'

const Result = () => {
    const [category, setCategory] = useState('FIRMNESS')
    const [topCategory, setTopCategory] = useState(() => {
        const categoryDetails = window.myData.filter(concern => concern.products.length > 0).sort((a, b) => b.risk_level - a.risk_level)
        return categoryDetails[0]
    })
    const [data, setData] = useState([])
    const [img, setImg] = useState('')
    const firmnessProduct = getRecommendedProducts('FIRMNESS')
    const faceWrinklesProduct = getRecommendedProducts('FACE_WRINKLES')
    const unevenSkinProduct = getRecommendedProducts('UNEVEN_SKIN')
    const skinDullnessProduct = getRecommendedProducts('SKIN_DULLNESS')
    const darkSpotsProduct = getRecommendedProducts('DARK_SPOTS')
    const hydrationProduct = getRecommendedProducts('HYDRATION')
    const fineLinesProduct = getRecommendedProducts('EYE_WRINKLES')
    const smoothnessProduct = getRecommendedProducts('SMOOTHNESS')
    const myRef = useRef()

    useEffect(() => {
        import('./result.css')
        console.log(window.finalData.data.sku_details)
        setData([...window.finalData.data.sku_details])
        setImg(window.myImage)
    }, [])

    const handleCategoryChange = (categoryName) => {
        setCategory(categoryName)
        handleTopCategoryChange(categoryName)
    }

    const scoreMapper = (current) => {
        const concern = window.myData.find(concern => concern.concern_name === current)
        return concern ? concern.value : 10
    }

    const handleScroll = (categoryName) => {
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setCategory(categoryName)
        handleTopCategoryChange(categoryName)
    }

    const handleTopCategoryChange = (current) => {
        current = window.myData.find(concern => concern.concern_name === current)
        console.log(current)
        setTopCategory({...current})
    }

    const scoreColor = (current) => {
        const concern = window.myData.find(concern => concern.concern_name === current)
        if(["HYDRATION", "SMOOTHNESS", "FIRMNESS", "OXYGEN", "TEXTURE"].includes(current)) {
            return (concern.value >= 0 && concern.value <= 39) ? 'rgb(252, 88, 88)'
            : (concern.value >= 40 && concern.value <= 79) ? 'rgb(244, 188, 28)'
            : 'rgb(179, 217, 54)'
        } else if(["SKIN_DULLNESS", "UNEVEN_SKIN", "DARK_SPOTS", "FACE_WRINKLES", "DARK_CIRCLE", "ACNE", "REDNESS", "EYE_WRINKLES", "PIGMENTATION", "CROWS_FEET", "LIPS_TEXTURE", "SHINE"].includes(current)) {
            return (concern.value >= 0 && concern.value <= 20) ? 'rgb(179, 217, 54)'
            : (concern.value >= 21 && concern.value <= 60) ? 'rgb(244, 188, 28)'
            : 'rgb(252, 88, 88)'
        } else if(["SKIN_HEALTH"].includes(current)) {
            return (concern.value >= 0 && concern.value <= 49) ? 'rgb(252, 88, 88)'
            : (concern.value >= 50 && concern.value <= 69) ? 'rgb(244, 188, 28)'
            : 'rgb(179, 217, 54)'
        }
    }

  return (
    <div className='result' id="result">
        <div className='result-part-1'>
            <img src='https://smartskinadvisor.dermafique.com/analysis/assets/newassets/topcurve.png' alt='bg-image' />
            <div className='result-top-section'>
                <h1>Let's go through the detailed breakdown of your result</h1>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Score score={scoreMapper("SKIN_HEALTH")} color={scoreColor("SKIN_HEALTH")} fontSize='30px' width='100px' height='100px' />
                    <span
                        style={{
                            paddingLeft: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '18px',
                            width: '100px',
                            textAlign: 'left'
                        }}
                    >Skin Health Score</span>
                </div>
            </div>
        </div>
        <div className='result-part-2'>
            <div className='result-solar-style'>
                <img src={img} alt='face-img' />
                <div className='result-planets'>
                    <div className='planet planet-1' onClick={handleScroll.bind(this, "FIRMNESS")} >
                        <Score score={scoreMapper("FIRMNESS")} color={scoreColor("FIRMNESS")} />
                        <span>Firmness</span>
                    </div>
                    <div className='planet planet-2' onClick={handleScroll.bind(this, "SMOOTHNESS")} >
                        <Score score={scoreMapper("SMOOTHNESS")} color={scoreColor("SMOOTHNESS")} />
                        <span>Smoothness</span>
                    </div>
                    <div className='planet planet-3'  onClick={handleScroll.bind(this, "HYDRATION")} >
                        <Score score={scoreMapper("HYDRATION")} color={scoreColor("HYDRATION")} />
                        <span>Hydration</span>
                    </div>
                    <div className='planet planet-4' onClick={handleScroll.bind(this, "SKIN_DULLNESS")} >
                        <Score score={scoreMapper("SKIN_DULLNESS")} color={scoreColor("SKIN_DULLNESS")} />
                        <span>Dullness</span>
                    </div>
                    <div className='planet planet-5' onClick={handleScroll.bind(this, "UNEVEN_SKIN")} >
                        <Score score={scoreMapper("UNEVEN_SKIN")} color={scoreColor("UNEVEN_SKIN")} />
                        <span style={{bottom:'-35px'}}>Uneven <br /> Skin Tone</span>
                    </div>
                    <div className='planet planet-6' onClick={handleScroll.bind(this, "EYE_WRINKLES")} >
                        <Score score={scoreMapper("EYE_WRINKLES")} color={scoreColor("EYE_WRINKLES")} />
                        <span style={{bottom:'-35px'}}>Fine lines <br /> & Wrinkles</span>
                    </div>
                    <div className='planet planet-7' onClick={handleScroll.bind(this, "DARK_SPOTS")} >
                        <Score score={scoreMapper("DARK_SPOTS")} color={scoreColor("DARK_SPOTS")} />
                        <span>Dark spots</span>
                    </div>
                </div>
            </div>
            <div className='priority-indicator'>
                <ul>
                    <li>Good</li>
                    <li>Ok</li>
                    <li>Bad</li>
                </ul>
            </div>
            <div className='score-recommendation'>
                <h1>Your key skin concerns & <br />
                Zendermis recommendations</h1>
                <ul>
                    {
                        firmnessProduct && (<li>
                            <Score score={scoreMapper("FIRMNESS")} color={scoreColor("FIRMNESS")} width='40px' height='40px'  onClick={handleScroll.bind(this, "firmness")} />
                            <span style={{margin: '10px 0 20px', fontSize: '14px'}}>Firmness</span>
                            <div className='recommended-product'>
                                <img src={firmnessProduct.image} alt='product' />
                                <a href={firmnessProduct.href}>
                                    <ShoppingBagOutlinedIcon />
                                    Buy now
                                    <ArrowRightAltOutlinedIcon />
                                </a>
                            </div>
                        </li>)
                    }
                    {
                        faceWrinklesProduct && (<li>
                            <Score score={scoreMapper("EYE_WRINKLES")} color={scoreColor("EYE_WRINKLES")} width='40px' height='40px' onClick={handleScroll.bind(this, "fineLines")} />
                            <span style={{margin: '10px 0 20px', fontSize: '14px'}}>
                                Fine Lines & <br />
                                Wrinkles
                            </span>
                            <div className='recommended-product'>
                                <img src={faceWrinklesProduct.image} alt='product' />
                                <a href={faceWrinklesProduct.href}>
                                    <ShoppingBagOutlinedIcon />
                                    Buy now
                                    <ArrowRightAltOutlinedIcon />
                                </a>
                            </div>
                        </li>)
                    }
                    {
                        unevenSkinProduct && (<li>
                            <Score score={scoreMapper("UNEVEN_SKIN")} color={scoreColor("UNEVEN_SKIN")} width='40px' height='40px' onClick={handleScroll.bind(this, "unevenSkinTone")} />
                            <span style={{margin: '10px 0 20px', fontSize: '14px'}}>Uneven Skin Tone</span>
                            <div className='recommended-product'>
                                <img src={unevenSkinProduct.image} alt='product' />
                                <a href={unevenSkinProduct.href}>
                                    <ShoppingBagOutlinedIcon />
                                    Buy now
                                    <ArrowRightAltOutlinedIcon />
                                </a>
                            </div>
                        </li>)
                    }
                </ul>
            </div>
            <div className='score-report' ref={myRef}>
                <h1>Your Detailed Skin Analysis Report</h1>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Score score={scoreMapper(topCategory.concern_name)} color={scoreColor(topCategory.concern_name)} width='70px' height='70px' fontSize='22px' />
                    <span
                        style={{
                            paddingLeft: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '16px',
                            textAlign: 'left'
                        }}
                    >One of your other skin concerns is {
                        topCategory.concern_name === 'FIRMNESS' ? 'firmness' :
                        topCategory.concern_name === 'SMOOTHNESS' ? 'smoothness' : 
                        topCategory.concern_name === 'HYDRATION' ? 'hydration' : 
                        topCategory.concern_name === 'SKIN_DULLNESS' ? 'dullness' :
                        topCategory.concern_name === 'UNEVEN_SKIN' ? 'uneven skin tone' :
                        topCategory.concern_name === 'EYE_WRINKLES' ? 'fine lines' :
                        topCategory.concern_name === 'DARK_SPOTS' ? 'dark spots' : <div></div>
                    }.</span>
                </div>
                <div className='product-tile-lg'>
                        <div>
                            <img src={window.finalData.data.sku_details.find(product => (product.sku === topCategory.products[0].sku_code)).image} alt='product-image' />
                        </div>
                        <div>
                            <strong>{window.finalData.data.sku_details.find(product => (product.sku === topCategory.products[0].sku_code)).name}</strong>
                            <p>Lightweight Cream that hydrates and nourishes the skin.</p>
                            <a href={window.finalData.data.sku_details.find(product => (product.sku === topCategory.products[0].sku_code)).href}>
                                <ShoppingBagOutlinedIcon />
                                Buy now
                                <ArrowRightAltOutlinedIcon />
                            </a>
                        </div>
                </div>
            </div>
            {
                category === 'FIRMNESS' ? <Firmness /> :
                category === 'SMOOTHNESS' ? <Smoothness /> : 
                category === 'HYDRATION' ? <Hydration /> : 
                category === 'SKIN_DULLNESS' ? <Dullness /> :
                category === 'UNEVEN_SKIN' ? <UnevenSkinTone /> :
                category === 'EYE_WRINKLES' ? <FineLines /> :
                category === 'DARK_SPOTS' ? <DarkSpots /> : null
            }
        </div>
        <div className='result-part-3'>
            <p style={{textAlign: 'center', marginBottom: '10px', fontSize: '14px'}}>Click below to view other Skin Health Parameters</p>
            <ul>
                <li data-active={category === 'FIRMNESSS'} onClick={handleCategoryChange.bind(this, 'firmness')}>Firmness</li>
                <li data-active={category === 'EYE_WRINKLES'} onClick={handleCategoryChange.bind(this, 'fineLines')}>Fine Lines & Wrinkles</li>
                <li data-active={category === 'UNEVEN_SKIN'} onClick={handleCategoryChange.bind(this, 'unevenSkinTone')}>Uneven Skin Tone</li>
                <li data-active={category === 'HYDRATION'} onClick={handleCategoryChange.bind(this, 'hydration')}>Hydration</li>
                <li data-active={category === 'SKIN_DULLNESS'} onClick={handleCategoryChange.bind(this, 'dullness')}>Dullness</li>
                <li data-active={category === 'SMOOTHNESS'} onClick={handleCategoryChange.bind(this, 'smoothness')}>Smoothness</li>
                <li data-active={category === 'DARK_SPOTS'} onClick={handleCategoryChange.bind(this, 'darkSpots')}>Dark Spots</li>
                <li>-</li>
            </ul>
        </div>
        <div className='result-part-4'>
            <img src={banner} alt='result-banner' width='100%' />
        </div>
        <div id="resultsHere" className="mt-25" style={{display: 'none'}}>
            <h3 className="mt-25">Recommendations</h3>
            <table id="recommendationTable" border="1">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Image</th>
                        <th>Price</th>
                        <th>Product Link</th>
                    </tr>
                </thead>
                <tbody>
                {
                    data && data.map((product, index) => {
                        return (
                            <tr key={index}>
                                <td>{product.name}</td>
                                <td>
                                    <img src={product.image} alt='product-image' width='50' height='50' />
                                </td>
                                <td>{parseFloat(product.special).toFixed(2)}   <del>{parseFloat(product.price).toFixed(2)}</del></td>
                                <td>
                                    <a href={product.href}>Buy Now</a>
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Result