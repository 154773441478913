import React from 'react'

const Score = ({className = "", score = "100", color = "#fc5858", fontSize = "13px", width = "100%", height = "100%", onClick}) => {
  return (
    <span className={'score '+className} style={{width:width, height:height }} onClick={onClick}>
        <svg class="circle-chart" height={height} viewBox="0 0 33.83098862 33.83098862" width={width} xmlns="http://www.w3.org/2000/svg">
            <circle _ngcontent-vxy-c0="" class="circle-chart__background" cx="16.91549431" cy="16.91549431" fill="none" r="15.91549431" stroke="#959b90" stroke-width=".1"></circle>

            <circle _ngcontent-vxy-c0="" class="circle-chart__circle" cx="16.91549431" cy="16.91549431" fill="none" r="15.91549431" stroke-linecap="round" stroke-width="2" stroke={color} stroke-dasharray={score+", 100"}></circle>
        </svg>
        <span className='score-value' style={{fontSize:fontSize,color:color}}>
            {score}%
        </span>
    </span>
  )
}

export default Score