import React, { useEffect, useState } from 'react'
import { CircularProgress, IconButton } from '@mui/material'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { authToken, productDetails } from '../../Utils/fetchData';

import face from '../../Assets/New/face-recognition.png'
import bluecurve from '../../Assets/New/uploadcurve-blue.png'

const Photo = ({setPage}) => {
    const iconButtonStyle = {
        display: 'inline-flex !important',
        background: '#0d3d82',
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        color: '#fff',
        margin: '20px 30px'
    }
    const [resourceLoading, setResourceLoading] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        const loadResources = async () => {
            try {
                // const linkcss = document.createElement('link');
                // linkcss.rel = 'stylesheet';
                // linkcss.href = 'https://orbostorage1test.blob.core.windows.net/skinansdkpsquared/orbo-psquared-skin-analyzer-1.0.0.css';
                // document.head.appendChild(linkcss);
                
                // const linkjs = document.createElement('script');
                // linkjs.type = 'text/javascript';
                // linkjs.src = 'https://orbostorage1test.blob.core.windows.net/skinansdkpsquared/orbo-psquared-skin-analyzer-1.0.0.js';
                // linkjs.async = true;
                // linkjs.defer = true;
                // linkjs.onload = () => {
                //     console.log('External script loaded successfully');
                // }

                // await document.head.appendChild(linkjs);

                await import('../Photo/photo.css')

                await import('../../Utils/Photo/scripts')

                setResourceLoading(false)
            } catch(err) {
                console.error(err)
            }
            setLoading(false)
        }
        if(resourceLoading) {
            loadResources()
        }
        return () => {
            setResourceLoading(false)
        }
        // eslint-disable-next-line
    }, [])

    const waitForMyData = () => {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
            if (window.myData) {
                clearInterval(interval);  // Stop polling
                resolve(window.myData);    // Resolve the promise with the data
            }
            }, 500);  // Check every 500ms
        
            // Timeout to prevent infinite waiting
            setTimeout(() => {
            clearInterval(interval);
            reject("Timeout: window.myData not available");
            }, 100000); // Timeout after 10 seconds
        });
    };

    const handleResultRedirection = async () => {
        setLoading(true)
        await waitForMyData()
        if(window.myData) {
            let tempArray = []
            window.myData.forEach((concern) => {
                tempArray = tempArray.concat(concern.products.map((product) => product.sku_code))
            })
            console.log(tempArray)
            const productSkusArray = [...new Set(tempArray)]
            console.log(productSkusArray)

            // token API
            if(!localStorage.getItem("apikey")) {
                await authToken();
            }

            // product-details API
            const productDetailsArray = await productDetails(productSkusArray)
            
            window.finalData = productDetailsArray

            console.log(window.finalData)
            setLoading(false)
            setPage('contact')
        }
    }

  return (
    <div className="photo">
        <img src="" alt="" />
        <div className='photo-top-section'>
            <p>It's time for you</p>
            <h1>No Makeup Selfie !</h1>
            <img id='smartCaptureThumbnail' className='visible' src={face} alt="face-area" />
            <div id="smartCaptureContainer" className="hidden"></div>
        </div>
        <div className='photo-bottom-section'>
            <img src={bluecurve} alt='bg-image' />
            <div className='photo-bottom-content'>
                <div>
                    <p style={{fontSize:'14px',color:'#0d3d82'}}>- Loading...</p>
                    <p>Click the picture in natural light and
                    keep a neutral expression</p>
                </div>
                <div>
                    <IconButton sx={iconButtonStyle} onClick={handleResultRedirection}>
                        <FileUploadOutlinedIcon />
                    </IconButton>
                    <IconButton id='startButton' sx={iconButtonStyle}>
                        <CameraAltOutlinedIcon />
                    </IconButton>
                    <IconButton id='nextButton' sx={{display:'none'}} onClick={handleResultRedirection}>
                        <CameraAltOutlinedIcon />
                    </IconButton>
                </div>
            </div>
        </div>
        <div id="resultsHere" className="mt-25 hidden">
            <div className="preview m-auto">
                <img id="imgPreview" className="w-100" alt="preview-image" />
            </div>
            <h3 className="mt-25">Recommendations</h3>
            {/* <table id="recommendationTable" border="1">
                <thead>
                    <tr>
                        <th>Concern Name</th>
                        <th>Risk Level</th>
                        <th>Value</th>
                        <th>Products</th>
                    </tr>
                </thead>
                <tbody></tbody>
            </table> */}

            <button type="button" id="startAgainBtn" className="mt-25">Start Again</button>
        </div>
    </div>
  )
}

export default Photo