import React from 'react'

const QuestionPage = ({children, title, description}) => {
  return (
    <div className='question-page'>
        <div className='question-background-top'>
            <img src='https://smartskinadvisor.dermafique.com/analysis/assets/newassets/topcurve.png' alt='bg-top-img' />
            <div className='question-top-section'>
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
        </div>
        <div className='question-background-middle'>
            {children}
        </div>
        <div className='question-background-bottom'>
            <img src='https://smartskinadvisor.dermafique.com/analysis/assets/newassets/bottomcurve.png' alt='bg-bottom-img' />
        </div>
    </div>
  )
}

export default QuestionPage