import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import GuidePage1 from "./Data/GuidePageData/GuidePage1";
import GuidePage2 from "./Data/GuidePageData/GuidePage2";
import GuidePage3 from "./Data/GuidePageData/GuidePage3";

const Guide = ({setPage}) => {
  const steps = [<GuidePage1 />, <GuidePage2 />, <GuidePage3 setPage={setPage} />];
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="guide">
      <Box className="slider-area">{steps[activeStep]}</Box>
      <Box 
        display="flex" 
        justifyContent="center" 
        m={2} 
        position="absolute" 
        bottom="0"
        left="100px"
        right="100px"
        sx={{ 
            zIndex:"1",
            display: (activeStep === (maxSteps - 1)) ? 'none' : 'flex',
            textDecoration: 'underline',
            fontWeight: '700',
            color: '#0d3d82'
        }}
        onClick={() => setActiveStep(maxSteps-1)}
      >
        SKIP
      </Box>
      <MobileStepper
        className="slider-controller"
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            sx={{
              display: (activeStep === maxSteps - 1) ? 'none' : '',
            }}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
        sx={{
          backgroundColor: "#fff",
          '& .MuiMobileStepper-dots': {
              position: 'relative',
              top: '-25px'
          },
          '& .MuiMobileStepper-dot': {
              display: (activeStep === maxSteps - 1) ? 'none' : '', // Default color for inactive dots
          }
        }}
      />
    </div>
  );
};

export default Guide;
