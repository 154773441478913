import React from 'react'
import GuidePage from '../../GuidePage'
import page2 from '../../../../Assets/Guide/2-4.png'
import logo from '../../../../Assets/Guide/logo.png'

const GuidePage2 = () => {
  return (
    <div>
      {/* Guide 2 */}
      <GuidePage imgPath={page2}>
          <h1>Hi, Welcome to</h1>
          <img
          src={logo}
          alt="title"
          />
          <p>
          AI-powered personalized skin health analysis
          </p>
          {/* <h1>Share your Selfie !</h1>
          <p>Just upload your image and let Smart SkinAdvisor's Artificial Intelligence powered Technology take care of the rest to generate your personalized Skin Health Analysis on 7 Skin Health Parameters and overall Skin Health Score </p> */}
      </GuidePage>
    </div>
  )
}

export default GuidePage2