import React, { useState } from 'react'
import { Button, TextField } from '@mui/material'

import { sendOtp, verifyOtp } from '../../Utils/fetchData' 

const Contact = ({setPage}) => {

    const [num, setNum] = useState('')
    const [otp, setOtp] = useState('')
    const [showOtp, setShowOtp] = useState(false)
    
    const handleNumberChange = (e) => {
        e.preventDefault()
        setNum(e.target.value)
    }
    
    const handleOtpChange = (e) => {
        e.preventDefault()
        setOtp(e.target.value)
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        
        const { data: { success } } = await sendOtp(num)

        if(success) {
            setShowOtp(true)
        }
    }
    
    const checkOtp = async (e) => {
        e.preventDefault()
        const { data : { success } } = await verifyOtp(num, otp)

        if(success) {
            setPage('result')
        } else {
            alert("Wrong OTP. Please enter the correct OTP.")
        }
    }

  return (
    <div className='contact'>
   <div className='contact-page'>
      <div className='contact-background-top'>
         {/* <img src='https://smartskinadvisor.dermafique.com/analysis/assets/newassets/topcurve.png' alt='bg-top-img' /> */}
         <div className="contact-top-section">
            <h1>Your result is ready!</h1>
         </div>
      </div>
      <div className='contact-background-middle'>
         <div className='loginForm'>
            <div className='registerBox'>
               <div className='surprise-container'>
                  <h5>WE HAVE A SURPRISE FOR YOU!</h5>
                  <p>Register now to unlock your gift!</p>
               </div>
               <TextField 
               id="phone" 
               label="Phone" 
               variant="outlined" 
               value={num}
               className='inputField' 
               onChange={handleNumberChange} 
               sx={{width: '100%',marginBottom:'15px'}}     
               />
               {
               (!showOtp) ? 
               <Button className='btnStyle' variant="contained" onClick={handleSubmit}>Submit</Button>
               : <>
               <TextField className='inputField'  id="otp" label="OTP" variant="outlined" value={otp} onChange={handleOtpChange} sx={{width: '100%',marginBottom:'15px'}} />
               <Button className='btnStyle' variant="contained" onClick={handleSubmit}>Resend OTP</Button>
               <Button className='btnStyle' variant="contained" onClick={checkOtp}>Verify OTP</Button> 
               </>
               }
            </div>
            <div class="dividerOr"><span>Or</span></div>
            <Button className='btnStyle' variant="contained" onClick={() => setPage('result')}>Continue as guest</Button> 
         </div>
      </div>
      <div className='contact-background-bottom'>
         {/* <img src='https://smartskinadvisor.dermafique.com/analysis/assets/newassets/bottomcurve.png' alt='bg-bottom-img' /> */}
      </div>
   </div>
</div>
  )
}

export default Contact