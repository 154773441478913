import React from 'react'

const ResultCategory = ({concernContent, solutionContent, expertTipContent}) => {
  return (
    <div className='result-tab-content'>
        <div className='result-category-wise concern'>
            <span>Concern</span>
            {concernContent}
        </div>
        <div className='result-category-wise solution'>
            <span>Solution</span>
            {solutionContent}
        </div>
        <div className='result-category-wise expert-tip'>
            <span>Expert Tip</span>
            {expertTipContent}
        </div>
    </div>
  )
}

export default ResultCategory