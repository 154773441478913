import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import QuestionPage1 from "./Data/QuestionPage1";
import QuestionPage2 from "./Data/QuestionPage2";
import QuestionPage3 from "./Data/QuestionPage3";

const Question = ({setPage}) => {
  const [dataJson, setDataJson] = useState([0, 35, 0])
  const steps = [<QuestionPage1 setData={setDataJson} />, <QuestionPage2 setData={setDataJson} />, <QuestionPage3 setData={setDataJson} />];
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    if(activeStep === maxSteps - 1) {
        setPage("photo")
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDotClick = (index) => {
    setActiveStep(index); // Update activeStep to the clicked dot index
  };    

  useEffect(() => {
    window.userData = dataJson
  }, [dataJson])

  return (
    <div className="question">
      <Box className="slider-area" sx={{height: '100%'}}>{steps[activeStep]}</Box>
      <Box 
        display="flex" 
        justifyContent="center" 
        m={2} 
        position="absolute" 
        bottom={(window.innerHeight <= 740) ? "40px" : "70px"}
        left={(window.innerHeight <= 740) ? "12%" : "20%"}
        sx={{ 
            zIndex:"1",
        }}
      >
        {Array.from({ length: maxSteps }).map((_, index) => (
          <Box
            key={index}
            sx={{
              padding: '10px',
              borderRadius: '50%',
              margin: 1,
              backgroundColor: activeStep === index ? '#0d3d82' : '#c5daf8',
              color: activeStep === index ? '#fff' : '#0d3d82',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
              fontSize: '25px',
              width: '50px',
              height: '50px',
              textAlign: 'center'
            }}
            onClick={() => handleDotClick(index)} // On click, set the active step
          >
            0{index+1}
            <div style={{fontSize:'10px'}}>{index+1 === 1 ? "Gender" : (index+1 === 2 ? "Age" : "Skin Type")}</div>
          </Box>
        ))}
      </Box>
      <MobileStepper
        className="slider-controller"
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
        sx={{
            '& .MuiMobileStepper-dot': {
                display: 'none', // Default color for inactive dots
            }
        }}
      />
    </div>
  );
};

export default Question;
