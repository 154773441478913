import React from "react";

import bg_img from '../../Assets/New/bottomcurve3.png';

const GuidePage = ({ children, imgPath }) => {
  return (
    <div className="guide-page">
      <div className="guide-page-background">
        <img src={imgPath} alt="guide-image" />
      </div>
      <div className="guide-page-content-overlay">
        <img src={bg_img} alt="bg-overlay" />
        <div className="guide-page-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default GuidePage;
