import React from "react";

const ResultImageRow = ({imgLink, children}) => {
  return (
    <li className="result-image-row">
        <div className="result-image-col">
            <img src={imgLink} alt="row-img" />
        </div>
        <div className="result-content-col">
            {children}
        </div>
    </li>
  );
};

export default ResultImageRow;
