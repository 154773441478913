import { useEffect, useState } from "react";
import "./App.css";
import Guide from "./Components/Guide/Guide";
import Question from "./Components/Question/Question";
import Photo from "./Components/Photo/Photo";
import Result from "./Components/Result/Result";
import Contact from "./Components/Contact/Contact";

function App() {
  const [page, setPage] = useState("guide")

  useEffect(() => {
    localStorage.removeItem("apikey")
  }, [])

  return (
    <div className="App">
      {(page === "guide") ? <Guide setPage={setPage} /> :
          page === "question" ? <Question setPage={setPage} /> : 
          page === "photo" ? <Photo setPage={setPage} /> : 
          page === "contact" ? <Contact setPage={setPage} /> :
          page === "result" ? <Result /> : null
      }
    </div>
  );
}

export default App;
