import React from 'react'
import ResultCategory from '../ResultCategory'
import ResultImageRow from '../ResultImageRow/ResultImageRow'

import LackofFirmness from '../../../Assets/Result/icons/lack of firmness op2.png'
import Anitoxidant from '../../../Assets/Result/icons/anti - oxidant op2.png'

import Extract from '../../../Assets/Result/icons/shea butter op1.png'
import Protein from '../../../Assets/Result/icons/protein op2.png'
import Water from '../../../Assets/Result/icons/hydration op1.png'
import Sun from '../../../Assets/Result/icons/sunscreen op1.png'

import Sleep from '../../../Assets/Result/icons/sleep op2.png'
import Hydration from '../../../Assets/Result/icons/hydration op2.png'
import Massage from '../../../Assets/Result/icons/firm healthy skin op1.png'
import Diet from '../../../Assets/Result/icons/diet rich vegetables and fruits op1.png'

const FineLines = () => {
  return (
    <ResultCategory  
      concernContent={
        <ul>
          <li>
            <p>Zendermis Skin Hydration Face Serum is formulated for intense hydration and instant plump looking skin. The unique low molecular weight hyaluronic acid penetrates into the deep dermal layer of the skin to provide optimum hydration and reduce the appearance of fine lines and wrinkles.</p>
          </li>
          <ResultImageRow imgLink={LackofFirmness} >
            Common Causes:
            <ul>
              <li>A buildup of dead skin cells.</li>
              <li>Reduced luminosity due to skin dryness and a slow turnover rate of new cells.</li>
              <li>Lack of Hydration.</li>
            </ul>
          </ResultImageRow>
          <ResultImageRow imgLink={Anitoxidant} >
            What you need:
            <ul>
              <li>A hydrating skincare solution that contains ingredients like Vitamin E, an antioxidant that is known to help repair cellular damage & reduce skin dullness.</li>
              <li>Skincare products that contain sea weed extracts are known to help brighten the skin and can also help reduce the appearance of skin dullness.</li>
            </ul>
          </ResultImageRow>
        </ul>
      }
      solutionContent={
        <ul>
          <li>
            <p>Vitamin C is the most potent form of  Ethyl Ascorbic Acid and the one that’s not only safe to use but also penetrates the skin from within to lighten the skin tone, giving it a more even-toned and healthy look. Ferulic Acid in the 10% Vitamin C Face Serum boosts Vitamin C’s antioxidant effects and helps protect overall skin integrity by reducing the development of fine lines, spots, and wrinkles. The HYALURONIC ACID in the serum Penetrates deep into the skin to infuse hydration in all conditions, making skin plumper, softer and smoother, while promoting skin cell regeneration.</p>
          </li>
          <li>
            <p>This lightweight yet deeply hydrating cream:</p>
          </li>
          <ResultImageRow imgLink={Extract} >
            Is infused with Sea Weed Extracts, that are known to contain vitamins & minerals which have hydrating & brightening properties.
          </ResultImageRow>
          <ResultImageRow imgLink={Protein} >
            Vitamin C is the most potent form of Ethyl Ascorbic Acid and the one that’s not only safe to use but also penetrates the skin from within to lighten the skin tone, giving it a more even-toned and healthy look.
          </ResultImageRow>
          <ResultImageRow imgLink={Water} >
            Ferulic Acid in the 10% Vitamin C Face Serum boosts Vitamin C’s antioxidant effects and helps protect overall skin integrity by reducing the development of fine lines, spots, and wrinkles.
          </ResultImageRow>
          <ResultImageRow imgLink={Sun} >
            The HYALURONIC ACID in the serum Penetrates deep into the skin to infuse hydration in all conditions, making skin plumper, softer and smoother, while promoting skin cell regeneration.
          </ResultImageRow>
        </ul>
      }
      expertTipContent={
        <ul>
          <li>
            <p>To prevent and reduce dullness:</p>
          </li>
          <ResultImageRow imgLink={Diet} >
            Ensure a healthy diet, rich in antioxidants and vitamins by including green leafy vegetables and nuts every day.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration} >
            Have ample hydration through the day to boost the glow on your skin.
          </ResultImageRow>
          <ResultImageRow imgLink={Massage} >
            Do regular facial self massages, yoga and exercises to improve blood circulation.
          </ResultImageRow>
          <ResultImageRow imgLink={Sleep} >
            Get adequate sleep of at least 7 hours at night.
          </ResultImageRow>
        </ul>
      }
    />
  )
}

export default FineLines