import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import QuestionPage from '../QuestionPage'
import Male from '../../../Assets/Question/2 (1).png'
import Female from '../../../Assets/Question/female op2.png'

const QuestionPage1 = ({ setData }) => {
    const settings = {
        arrows:  false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        className: 'center',
        centerPadding: (window.innerWidth <= "420") ? "95px" : "120px",
        afterChange: (index) => {
            setData(prevData => {
                const newData = [...prevData]
                newData[0] = index
                return newData
            })
        },
    }

  return (
    <QuestionPage 
        title="Select Gender"
        description="Structural differences are noticed between men's and women's skin."
    >
        <Slider {...settings}>
            <div className='gender-option'>
                <img src={Female} alt='female' />
            </div>
            <div className='gender-option'>
                <img src={Male} alt='male' />
            </div>
        </Slider>
    </QuestionPage>
  )
}

export default QuestionPage1